import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { FormControl, FormControlLabel, RadioGroup, Radio, Paper, Grid } from '@mui/material'
import Loading from '../../common/Loading'

const DashboardCharts = ({ chartType, counts }) => {
  const [chartData, setChartData] = useState(null)
  const [timeFrame, setTimeFrame] = useState('weekly')

  useEffect(() => {
    if (counts) {
      setChartData(processData(counts))
    }
  }, [counts])

  const processData = (data) => {
    const processed = {
      payments: { monthly: {}, weekly: {} },
      orders: { monthly: {}, weekly: {} },
      users: { monthly: {}, weekly: {} }
    }

    data.payment.forEach(payment => {
      const date = new Date(payment.createdAt)
      const month = date.toLocaleString('default', { month: 'short', year: 'numeric' })
      const week = `${date.getFullYear()}-W${getWeekNumber(date)}`
      const type = payment.type === 'Square' ? 'Square' : 'Store'

      if (!processed.payments.monthly[month]) processed.payments.monthly[month] = { Square: 0, Store: 0 }
      if (!processed.payments.weekly[week]) processed.payments.weekly[week] = { Square: 0, Store: 0 }
      processed.payments.monthly[month][type]++
      processed.payments.weekly[week][type]++
    })

    data.order.forEach(order => {
      const date = new Date(order.createdAt)
      const month = date.toLocaleString('default', { month: 'short', year: 'numeric' })
      const week = `${date.getFullYear()}-W${getWeekNumber(date)}`

      if (!processed.orders.monthly[month]) processed.orders.monthly[month] = 0
      if (!processed.orders.weekly[week]) processed.orders.weekly[week] = 0
      processed.orders.monthly[month]++
      processed.orders.weekly[week]++
    })

    data.customer.forEach(user => {
      const date = new Date(user.createdAt)
      const month = date.toLocaleString('default', { month: 'short', year: 'numeric' })
      const week = `${date.getFullYear()}-W${getWeekNumber(date)}`

      if (!processed.users.monthly[month]) processed.users.monthly[month] = 0
      if (!processed.users.weekly[week]) processed.users.weekly[week] = 0
      processed.users.monthly[month]++
      processed.users.weekly[week]++
    })

    return processed
  }

  const getWeekNumber = (date)=>{
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
    return Math.ceil((((date - yearStart) / 86400000) + 1) / 7)
  }

  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value)
  }

  if (!chartData) {
    return <Loading />
  }

  const paymentChartOptions = {
    chart: {
      type: 'bar',
      stacked: false,
    },
    xaxis: {
      categories: Object.keys(chartData.payments[timeFrame]),
    },
    yaxis: {
      title: {
        text: 'Count',
      },
    },
    title: {
      text: `Square Pay vs Store Pay (${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)})`,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return `${y}`;
          }
          return y;
        }
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
  }

  const paymentChartSeries = [
    {
      name: 'Square Pay',
      data: Object.values(chartData.payments[timeFrame]).map(v=>v.Square),
    },
    {
      name: 'Store',
      data: Object.values(chartData.payments[timeFrame]).map(v=>v.Store),
    },
  ]

  const orderChartOptions = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: Object.keys(chartData.orders[timeFrame]),
    },
    yaxis: {
      title: {
        text: 'Order',
      },
    },
    title: {
      text: `Orders (${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)})`,
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      }
    },
  }

  const orderChartSeries = [
    {
      name: 'Orders',
      data: Object.values(chartData.orders[timeFrame]),
    },
  ]

  const userChartOptions = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: Object.keys(chartData.users[timeFrame]),
    },
    yaxis: {
      title: {
        text: 'New Customers',
      },
    },
    title: {
      text: `New Customers (${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)})`,
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      }
    },
  }

  const userChartSeries = [
    {
      name: 'New Customers',
      data: Object.values(chartData.users[timeFrame]),
    },
  ]

  const renderChart = () => {
    switch (chartType) {
      case 'orders':
        return <Chart options={orderChartOptions} series={orderChartSeries} type="bar" height={350} />
      case 'payments':
        return <Chart options={paymentChartOptions} series={paymentChartSeries} type="bar" height={350} />
      case 'customers':
        return <Chart options={userChartOptions} series={userChartSeries} type="bar" height={350} />
      default:
        return null
    }
  }

  return (
    <Paper elevation={4} style={{ padding: '20px' }}>
      <FormControl component="fieldset">
        <RadioGroup row value={timeFrame} onChange={handleTimeFrameChange}>
          <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
          <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
        </RadioGroup>
      </FormControl>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderChart()}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DashboardCharts