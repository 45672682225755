import React, { useEffect, useState } from 'react'
import { fetchAnalyticsData } from '../api/action/query'
import Loading from "../../common/Loading"
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Collapse, Pagination, Tooltip, IconButton } from '@mui/material'
import { ExpandMore, ExpandLess, PlayCircleOutline } from '@mui/icons-material'

const Recommendation = () => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showTwo, setShowTwo] = useState(false)
    const [pageThree, setPageThree] = useState(1)
    const [pageTwo, setPageTwo] = useState(1)
    const rowsPerPage = 8

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const response = await fetchAnalyticsData(4)
                setData(response.data)
            } catch (error) {
                console.error("Error fetching recommendation data:", error)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [])

    if (isLoading) return <Loading />

    const handleChangePageThree = (event, newPage) => setPageThree(newPage)
    const handleChangePageTwo = (event, newPage) => setPageTwo(newPage)
    const startThree = (pageThree-1)*rowsPerPage
    const endThree = startThree+rowsPerPage
    const startTwo = (pageTwo-1)*rowsPerPage
    const endTwo = startTwo+rowsPerPage

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography 
                    variant="h6" 
                    color="primary" 
                    onClick={() => setShowTwo(!showTwo)}
                    style={{ marginBottom: '20px', cursor: 'pointer' }}
                >
                    {showTwo ? 'Show Triples':'Show Combos'}
                    {showTwo ? <ExpandLess /> : <ExpandMore />}
                </Typography>
                <Tooltip title="Run">
                    <IconButton
                        size="large"
                        variant="contained"
                        color="primary"
                        // onClick={() => console.log("Run button clicked")}
                        //need to implement after python script integrated 
                    >
                        <PlayCircleOutline fontSize="medium"/>
                    </IconButton>
                </Tooltip>
            </div>

            <Collapse in={!showTwo}>
                <Typography variant="h6" gutterBottom>Triples</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Item 1</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Item 2</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Item 3</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Predicted Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.three_item_combinations.slice(startThree, endThree).map((combination, index) => {
                                const items = combination[0]
                                const times = combination[1]

                                return (
                                    <TableRow key={index}>
                                        <TableCell>{items[0]}</TableCell>
                                        <TableCell>{items[1]}</TableCell>
                                        <TableCell>{items[2]}</TableCell>
                                        <TableCell>{times}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <Pagination
                        count={Math.ceil(data.three_item_combinations.length / rowsPerPage)}
                        page={pageThree}
                        onChange={handleChangePageThree}
                        color="primary"
                        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' , paddingBottom: '20px'}}
                    />
            </Collapse>

            <Collapse in={showTwo}>
                <Typography variant="h6" gutterBottom>Combos</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Item 1</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Item 2</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Predicted Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.two_item_recommendations.slice(startTwo, endTwo).map((recommendation, index) => (
                                <TableRow key={index}>
                                    <TableCell>{recommendation[0]}</TableCell>
                                    <TableCell>{recommendation[1]}</TableCell>
                                    <TableCell>{recommendation[2]}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Pagination
                        count={Math.ceil(data.two_item_recommendations.length / rowsPerPage)}
                        page={pageTwo}
                        onChange={handleChangePageTwo}
                        color="primary"
                        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' , paddingBottom: '20px'}}
                    />
            </Collapse>
        </div>
    )
}

export default Recommendation