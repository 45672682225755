// Imports
import Button from '@mui/material/Button';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { messageShow, upload } from '../../common/api/actions';
import SectionPaper from '../../common/SectionPaper';
import PropTypes from 'prop-types';
import Loading from '../../common/Loading';
import EmptyMessage from '../../common/EmptyMessage';
import { getImageSource } from '../api/routes';
import ImageComp from '../../common/Image';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { nullToEmptyString } from '../../../setup/helpers';
import { remove } from '../api/actions/mutation';
import routes from '../api/routes';
import styles from './styles';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@mui/material/MenuItem';
import { getGoal } from '../api/actions/query';
import { updateGoal } from '../api/actions/mutation';
// UI Imports
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import FileUpload from '@mui/icons-material/FileUpload';

// Component
class UpdateGoals extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      goal: {
        goalName: '',
        description: '',
        eventName: '',
        minOrderValue: '',
        startDate: dayjs(),
        expiryDate: dayjs(),
        startTime: dayjs().hour(0).minute(0),
        endTime: dayjs().hour(0).minute(0),
        rewardPoints: '',
        multiplierEvent: '',
        image: 'default.jpg',
      },
      startDateError: false,
    };
  }

  isFormValid = () => {
    const { goal } = this.state;

    // Check required fields
    const requiredFieldsFilled =
      goal.goalName.trim() &&
      goal.eventName.trim() &&
      goal.minOrderValue &&
      goal.multiplierEvent &&
      goal.rewardPoints;

    // Check date validity
    const validDates =
      goal.expiryDate.isValid() && !goal.expiryDate.isBefore(goal.startDate); 

    return requiredFieldsFilled && validDates;
  };

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const { getGoal, isLoading, match: { params: { goalId } } } = this.props;
    this.isLoadingToggle(isLoading);

    try {
      const { data } = await getGoal({ id: goalId });
      if (data.success) {
        this.setState({
          goal: {
            ...data.data,
            startDate: dayjs(data.data.startDate), 
            expiryDate: dayjs(data.data.expiryDate), 
          },
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow('There was some error. Please try again.', error);
    } finally {
      this.isLoadingToggle(false);
    }
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({ isLoadingSubmit });
  };

  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({ isUploadingFile });
  };

  handleDateChange = (key) => (date) => {
    const selectedDate = dayjs(date);
    const isError = selectedDate.isBefore(dayjs(), 'day'); 
    this.setState((prevState) => ({
      goal: {
        ...prevState.goal,
        [key]: dayjs(date), 
      },
      startDateError: isError, 
    }));
  };

  onUpload = async (event) => {
    const { upload, messageShow } = this.props;

    messageShow("Uploading file, please wait...");

    this.isUploadingFileToggle(true);

    const imgFile = event.target.files[0];
    const file = new FormData();
    file.append("type", "goals");
    file.append("name", this.state.goal.goalName);
    file.append("file", imgFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(imgFile);

    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {
        messageShow("File uploaded successfully.");

        const { goal } = this.state;

        setTimeout(() => {
          goal.image = data.file;
          this.setState({ goal });
        }, 1500);
      } else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };

  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      this.props.history.push(routes.listAllTabs.path, { activeTab: 'Goals' });
    } else {
      history.goBack();
    }
  }

  isLoadingToggle = (isLoading) => {
    this.setState({ isLoading });
  }

  onUpdate = async (event) => {
    event.preventDefault();

    const { updateGoal, messageShow, history } = this.props;
    const { goal } = this.state;

    const today = dayjs().startOf('day');
    if (goal.expiryDate.isBefore(today)) {
      messageShow("Expiry date must be today or a future date.");
      return;
    }
    // Date validation
    if (goal.expiryDate.isBefore(goal.startDate)) {
      messageShow("Expiry date must be equal to or greater than start date.");
      return;
    }

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await updateGoal(goal);
      this.isLoadingSubmitToggle(false);
      messageShow(data.message);

      if (data.success) {
        this.props.history.push(routes.listAllTabs.path, { activeTab: 'Goals' });
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);
      messageShow("Some error occurred. Please try again in update.", error);
    }
  };

  onType = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      goal: {
        ...prevState.goal,
        [name]: value,
      },
    }));
  };

  onTypeNumber = (event) => {
    const { name, value } = event.target;

    // Check if the entered value is a number
    if (!isNaN(value) && /^\d*$/.test(value)) {
      this.setState((prevState) => ({
        goal: {
          ...prevState.goal,
          [name]: value,
        },
      }));
    }
  };

  render() {
    const { classes } = this.props;
    const { goal, startDateError } = this.state;

    return (
      <SectionPaper>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit">
            Update Goals
          </Typography>
        </Toolbar>

        <Toolbar className={classes.toolbar}>
          <Grid item xs={12} lg={6}>
            <SectionPaper padding>
              <form onSubmit={this.onUpdate}>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"goalName"}
                    value={nullToEmptyString(goal.goalName)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Goal Name"}
                    placeholder={"Enter goal name"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"description"}
                    value={nullToEmptyString(goal.description)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Description"}
                    placeholder={"Enter description"}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name="eventName"
                      value={nullToEmptyString(goal.eventName)}
                      variant="standard"
                      onChange={this.onType}
                      label="Event name"
                      placeholder={"Enter event name"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name="minOrderValue"
                      value={nullToEmptyString(goal.minOrderValue)}
                      variant="standard"
                      onChange={this.onTypeNumber}
                      label="Minimum Order Value"
                      placeholder={"Enter Minimum Order Value"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"multiplierEvent"}
                      value={nullToEmptyString(goal.multiplierEvent)}
                      variant="standard"
                      onChange={this.onTypeNumber}
                      label={"Multiplier event"}
                      placeholder={"Enter number of multiplier events"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"rewardPoints"}
                      value={nullToEmptyString(goal.rewardPoints)}
                      variant="standard"
                      onChange={this.onTypeNumber}
                      label={"Rewards points"}
                      placeholder={"Enter number of rewards points"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} container spacing={2} style={{ marginBottom: 24 }}>
                <Grid item xs={6}>
                  <DatePicker
                    required
                    label="Start Date"
                    value={dayjs(goal.startDate)}
                    slotProps={{
                      textField: {
                        variant: "standard",
                        error: startDateError,
                        style: {
                          color: startDateError ? 'red' : 'inherit',
                        },
                      },
                    }}
                    onChange={this.handleDateChange('startDate')}
                    shouldDisableDate={(date) => date.isBefore(dayjs().startOf('day'))} 
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    required
                    value={dayjs(goal.expiryDate)}
                    label="Expiry Date"
                    slotProps={{ textField: { variant: "standard" } }}
                    onChange={this.handleDateChange('expiryDate')}
                    shouldDisableDate={(date) => {
                      const startDate = dayjs(goal.startDate);
                      return date.isBefore(dayjs().startOf('day')) || date.isBefore(startDate, 'day'); 
                    }}
                  />
                </Grid>
                  <Grid
                    container
                    spacing={10}
                    className={classes.buttonUpload}
                    alignItems="center"
                  >
                    <Grid item md={6}>
                      {!this.state.previewImage ? (
                        <a
                          href={getImageSource(goal.image)}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <ImageComp
                            src={getImageSource(`${goal.image}`)}
                            defaultSrc={getImageSource()}
                            size={"100%"}
                          />
                        </a>
                      ) : (
                        <img
                          height="auto"
                          width="100%"
                          src={this.state.previewImage}
                          alt="preview_goals"
                        />
                      )}
                    </Grid>
                    <Grid item md={6}>
                      <input
                        accept={"image/png,image/jpeg"}
                        style={{ display: "none" }}
                        id={"contained-button-file"}
                        type={"file"}
                        onChange={this.onUpload}
                      />

                      <label htmlFor={"contained-button-file"}>
                        <Button
                          variant={"outlined"}
                          component={"span"}
                          type={"file"}
                          fullWidth
                        >
                          <FileUpload
                            fontSize="16"
                            style={{ marginInlineEnd: 16 }}
                          />
                          Upload Image
                        </Button>
                      </label>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12} className={classes.buttonsContainer}>
                  <Button
                    type={"button"}
                    aria-label={"Close"}
                    variant="outlined"
                    color="secondary"
                    onClick={this.back}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginInlineStart: 12 }}
                    type={"submit"}
                    aria-label={"Save"}
                    color={"primary"}
                    variant="contained"
                    disabled={!this.isFormValid()} // Disable if form is not valid
                  >
                    Update
                  </Button>
                </Grid>
              </form>

            </SectionPaper>
          </Grid>
        </Toolbar>
      </SectionPaper>
    );
  }
}

UpdateGoals.propTypes = {
  updateGoal: PropTypes.func.isRequired,
  getGoal: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

function listState(state) {
  return {
    categoriesParent: state.categoriesParent,
  };
}

export default connect(listState, {
  getGoal,
  remove,
  updateGoal,
  upload,
  messageShow,
})(withStyles(styles)(UpdateGoals));


