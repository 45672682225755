// Imports
import { Grid, withStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import NotificationsIcon from "@material-ui/icons/Notifications";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { PureComponent, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { messageShow } from "../../common/api/actions";
import SectionPaper from "../../common/SectionPaper";
import PropTypes from "prop-types";
import Loading from "../../common/Loading";
import EmptyMessage from "../../common/EmptyMessage";
import { remove } from "../api/actions/mutation";
import routes from "../api/routes";
import styles from "./styles";
import dateFormat from "date-fns/format";
import dateParseISO from "date-fns/parseISO";
import { listGoals } from "../api/actions/query";
import { getImageSource } from "../api/routes";
import Image from "../../common/Image";

// Component
class Goals extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      goals: [],
      count: 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const { listGoals, isLoading } = this.props;
    this.isLoadingToggle(isLoading);
    try {
      const { data } = await listGoals();
      if (data.success) {
        this.setState({
          goals: data.data,
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };
  isValidDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize the time portion
    return date >= today; // Check if the date is today or in the future
  };

  handleDateChange = (dateType, date) => {
    if (!this.isValidDate(date)) {
      alert("Please select a current or future date.");
      return;
    }
    
    this.setState({ [dateType]: date });
  };

  onDelete = async (goalId) => {
    let check = window.confirm(
      "Are you sure you want to delete this category?"
    );

    if (check) {
      const { remove, messageShow } = this.props;

      try {
        const { data } = await remove({ goalId });

        messageShow(data.message);

        if (data.success) {
          this.refresh();
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  render() {
    const {goals , isLoading } = this.state;
    const list = goals
    const columnCount = 10; // Number of columns in the table
    return (
      <div>
        <Grid style={{ textAlign: "right", paddingTop: "20px" }}>
          <Link to={routes.creategoals.path}>
            <Button
              aria-label={"Save"}
              size="medium"
              color={"primary"}
              variant="contained"
            >
              CREATE
            </Button>
          </Link>
        </Grid>
        
          <SectionPaper>
            <Table padding={"normal"}>
            <TableHead>
              <TableRow>
                <TableCell width={100}>Image</TableCell>
                <TableCell width={150}>Goal name</TableCell>
                <TableCell width={160}>Description</TableCell>
                <TableCell width={150}>Event name</TableCell> 
                <TableCell width={150}>Minimum Order Delivery</TableCell> 
                <TableCell width={150}>Multiplier event</TableCell>
                <TableCell width={180}>Reward points </TableCell> 
                <TableCell width={140}>Start date </TableCell> 
                <TableCell width={140}>Expiry date </TableCell> 
                <TableCell width={180} align="center">                  
                  Actions </TableCell> 
                </TableRow>
            </TableHead>
                
            {isLoading ? (
              <Loading />
                ) : list.length === 0 ? (
                     <TableBody>
                      <TableRow>
                        <TableCell colSpan={columnCount} style={{
                          textAlign: 'center',
                          padding: '25px',
                          fontSize: '20px',
                          variant: 'h6',
                        }}>
                          No Goals Found 
                        </TableCell>
                        {/* <EmptyMessage message={" No products found"} /> */}
                      </TableRow>
                      </TableBody>     
                ) : (
                <TableBody>
                
                {list.map(({_id,image,goalName,description,eventName,minOrderValue,multiplierEvent,rewardPoints,startDate,expiryDate }) => (

                <TableRow key={_id}>
                <TableCell>
                 <Image
                 src={getImageSource(`${image}`)}
                 defaultSrc={getImageSource()}
                 />             
                </TableCell>
                <TableCell>{goalName}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell align="center">{eventName}</TableCell>
                <TableCell align="center">{minOrderValue}</TableCell>
                <TableCell>{multiplierEvent}</TableCell>
                <TableCell>{rewardPoints}</TableCell>
                <TableCell>{ dateFormat(dateParseISO(startDate), 'yyyy-MM-dd') }</TableCell>
                <TableCell>{ dateFormat(dateParseISO(expiryDate), 'yyyy-MM-dd') }</TableCell>
                <TableCell>
                  <div style={{  width: 200 }}>
                  <Button href={`/goals/edit/${_id}`}><EditIcon/></Button>
                  <Button  color="error" onClick={() => this.onDelete(_id)}>
                  <DeleteIcon />  
                  </Button>
                  <Button style={{  width: 30 }}>
                  <NotificationsIcon/>
                  </Button>
                  </div> 
                  </TableCell>
                  </TableRow>
                ))}

                </TableBody>
                )} 

                </Table>
              
          </SectionPaper>
      </div>
    );
  }
}
Goals.propTypes = {
  listGoals: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
function listState(state) {
  return {
    goalsParent: state.goalsParent,
  };
}

export default connect(listState, {
  listGoals,
  remove,
  messageShow,
})(withStyles(styles)(Goals));
