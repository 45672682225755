// Imports
import { withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import routes from "../api/routes";
import { withRouter } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PropTypes from "prop-types";
import styles from "./styles";
import Points from "../Points";
import Goals from "../listGoals";
import Rewards from "../listRewards";
import Leaderboard from "../Leaderboard/List/index";

// Component
class List extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.getActiveTabFromPath(
        props.location.pathname,
        props.location.state
      ),
      dataFetched: {
        Goals: false,
        Points: false,
        Rewards: false,
        LeaderBoard: false,
      },
    };
  }

  // Define getActiveTabFromPath as an arrow function
  getActiveTabFromPath = (path, state) => {
    if (state && state.activeTab) {
      return state.activeTab;
    }
    if (path.includes(routes.listGoals.path)) {
      return "Goals";
    } else if (path.includes(routes.listPoints.path)) {
      return "Points";
    } else if (path.includes(routes.listRewards.path)) {
      return "Rewards";
    } else if (path.includes(routes.listLeaderboard.path)) {
      return "LeaderBoard";
    }
    return "Goals";
  };

  handleSelect = (key) => {
    this.setState({ activeTab: key });
    let path;
    switch (key) {
      case "Goals":
        path = routes.listAllTabs.path; // Assuming this is the correct path for Goals
        break;
      case "Points":
        path = routes.listPoints.path;
        break;
      case "Rewards":
        path = routes.listRewards.path;
        break;
      case "LeaderBoard":
        path = routes.listLeaderboard.path; // Correct path for LeaderBoard
        break;
      default:
        path = routes.listAllTabs.path; // Fallback to Goals or any default
    }
    this.props.history.push(path, { activeTab: key });
  };

  // Fetch data based on tab
  fetchData = (tab) => {
    if (!this.state.dataFetched[tab]) {
      console.log(`Fetching ${tab} API...`); // Ensure this logs only once per tab
      // Simulate API call
      setTimeout(() => {
        this.setState((prevState) => ({
          dataFetched: {
            ...prevState.dataFetched,
            [tab]: true,
          },
        }));
      }, 1000);
    } else {
      console.log(`${tab} data already fetched.`);
    }
  };
  

  // Fetch data when the component mounts
  componentDidMount() {
    this.fetchData(this.state.activeTab);
  }

  // Control the fetchData call after tab change
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.fetchData(this.state.activeTab);
    }
  }

  render() {
    const { activeTab, dataFetched } = this.state;
    return (
      <section id="menu" className="mt-5">
        <div className="clearfix mb-5 pb-5">
          <Container fluid>
            <Row className="mb-10 justify-content-center">
              <Col>
                <Tabs
                  activeKey={activeTab}
                  onSelect={this.handleSelect}
                  transition={false}
                >
                  <Tab eventKey="Goals" title="Goals">
                    {dataFetched.Goals ? <Goals /> : <div>Loading Goals...</div>}
                  </Tab>
                  <Tab eventKey="Points" title="Points">
                    {dataFetched.Points ? <Points /> : <div>Loading Points...</div>}
                  </Tab>
                  <Tab eventKey="Rewards" title="Rewards">
                    {dataFetched.Rewards ? <Rewards /> : <div>Loading Rewards...</div>}
                  </Tab>
                  <Tab eventKey="LeaderBoard" title="LeaderBoard">
                    {dataFetched.LeaderBoard ? (
                      <Leaderboard />
                    ) : (
                      <div>Loading LeaderBoard...</div>
                    )}
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(connect(null, {})(withStyles(styles)(List)));

